import {routes} from "../routes";

export const aimEmailOpen = (
    host: string,
    state: {"dgid"?: string; "campaignCode"?: string}
) => {
    return `<img src="${host}/_icu.png?dgid=${state.dgid}&campaign=${
        state.campaignCode
    }" />
<img src="${host}/_itu.png" />
<a href="${host}/toc/link/?key=8b142b98&id=${
    state.dgid
}&email=$EMAIL_ADDRESS_$&launch_id=$launchid()$&esp_id=61&deployed=1539979720&deployment_id=0&data=$KEYCODE$+$DMDSID$&click=${encodeURI(
    window.location.origin + routes.html[220].path
)}">
    Tag on Click Link
</a>
`;
};

export default aimEmailOpen;
