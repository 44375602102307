import React from "react";
import {CookiesProvider} from "react-cookie";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./assets/app.css";
import App from "./containers/App";

const rootElement = document.getElementById("root");

if (rootElement) {
    ReactDOM.createRoot(rootElement).render(
        <CookiesProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </CookiesProvider>
    );
} else {
    console.error("Root element not found");
}
