import adler32 from "adler-32";
import partners from "../../config/partners";
import {querystring} from "../url/querystring";

export const generateUrl = ({
    identityType,
    identityValue,
    partnerId,
    targetUrl,
}: {
    "identityType": string;
    "identityValue": string;
    "partnerId": keyof typeof partners;
    "targetUrl": string;
}) => {
    if (undefined === partners[partnerId]) {
        return;
    }

    const characterMap = partners[partnerId].map;
    const input = `${identityType}|${identityValue}`;
    const encodedB64 = btoa(input)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "") as ArrayLike<keyof (typeof partners)["1"]["map"]>;

    const mappedValue = Array.from(encodedB64)
        .map(x => characterMap[x])
        .join("");

    const partnerAuthentication = `${partnerId}-${mappedValue}-${Math.abs(
        adler32.str(mappedValue)
    )}`;

    const queryData = [{"de-p": partnerAuthentication}];
    return (
        targetUrl + querystring(queryData, targetUrl.includes("?") ? "&" : "?")
    );
};
