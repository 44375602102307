import {routes} from "../routes";
import {customDimensions} from "../snippets/gtm/custom_dimensions";
import {integrationDefault} from "../snippets/gtm/integration_default";

export const gtmIntegrationPages = [
    {
        "route": routes.html[800],
        "script": integrationDefault,
        "links": {
            "publicDoc": "https://docs.dmdconnects.com/aim/implementation/gtm",
        },
    },
    {
        "route": routes.html[801],
        "script": customDimensions,
        "links": {
            "publicDoc":
                "https://docs.dmdconnects.com/aim/integration/gtm-custom-dimensions",
        },
    },
];
