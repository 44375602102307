import {routes} from "../routes";
import {aimReaderGetSession} from "../snippets/aim_reader_get_session";
import {aimReaderInit} from "../snippets/aim_reader_init";
import {aimReaderInitCallback} from "../snippets/aim_reader_init_callback";
import {aimReaderInitOndetect} from "../snippets/aim_reader_init_ondetect";
import {aimReaderInitOndetectNoAimGlobal} from "../snippets/aim_reader_init_ondetect_no_aim_global";
import {aimReaderInitOndetectWithError} from "../snippets/aim_reader_init_ondetect_with_error";
import {aimReaderInitOndetectWithFetch} from "../snippets/aim_reader_init_ondetect_with_fetch";
import {aimReaderInitOnloadPageview} from "../snippets/aim_reader_init_onload_pageview";
import {aimReaderInitOnloadPageviewTrue} from "../snippets/aim_reader_init_onload_pageview_true";
import {aimReaderInitPageview} from "../snippets/aim_reader_init_pageview";
import {aimReaderInitPromise} from "../snippets/aim_reader_init_promise";
import {aimReaderInitVersion} from "../snippets/aim_reader_init_version";
import {aimReaderInitWithoutApiKey} from "../snippets/aim_reader_init_without_api_key";
import {aimReaderPartnerTracker} from "../snippets/aim_reader_partner_tracker";

export const legacyReaderSinglePages = [
    {
        "route": routes.html[200],
        "script": aimReaderInit,
    },
    {
        "route": routes.html[201],
        "script": aimReaderInitWithoutApiKey,
    },
    {
        "route": routes.html[202],
        "script": aimReaderInitOnloadPageview,
    },
    {
        "route": routes.html[203],
        "script": aimReaderInitOnloadPageviewTrue,
    },
    {
        "route": routes.html[204],
        "script": aimReaderInitCallback,
    },
    {
        "route": routes.html[205],
        "script": aimReaderPartnerTracker,
    },
    {
        "route": routes.html[220],
        "script": aimReaderInitOndetect,
    },
    {
        "route": routes.html[221],
        "script": aimReaderInitOndetectWithFetch,
    },
    {
        "route": routes.html[222],
        "script": aimReaderInitPageview,
    },
    {
        "route": routes.html[223],
        "script": aimReaderGetSession,
    },
    {
        "route": routes.html[224],
        "script": aimReaderInitVersion,
    },
    {
        "route": routes.html[225],
        "script": aimReaderInitPromise,
    },
    {
        "route": routes.html[226],
        "script": aimReaderInitOndetectNoAimGlobal,
    },
    {
        "route": routes.html[227],
        "script": aimReaderInitOndetectWithError,
    },
];
