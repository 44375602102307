import EnvironmentSelector from "../../components/EnvironmentSelector";

const environmentSelector = new EnvironmentSelector();
const env = environmentSelector.getEnvironment();
const s3Env = env === "local" ? "sbx" : env;
const s3 = `https://console.aws.amazon.com/s3/buckets/dmd-edw-firehose-data-taiga-${s3Env}`;
const s3Data = `${s3}/aim/v4/`;
const s3Errors = `${s3}/error-output/`;

export const data = {
    "s3": {
        "authenticate": `${s3Data}authenticate/?region=us-east-1`,
        "tag": `${s3Data}tag/?region=us-east-1`,
        "pageLoad": `${s3Data}page-load/?region=us-east-1`,
        "domContentLoaded": `${s3Data}dom-content-loaded/?region=us-east-1`,
        "signal": `${s3Data}signal/?region=us-east-1`,
        "fingerprint": `${s3Data}fingerprint/?region=us-east-1`,
        "errorOutput": s3Errors,
    },
};
