export const config = [
    {
        "text": "local",
        "value": "local",
    },
    {
        "text": "sbx",
        "value": "sbx",
    },
    {
        "text": "dev",
        "value": "dev",
    },
    {
        "text": "qa",
        "value": "qa",
    },
    {
        "text": "stg",
        "value": "stg",
    },
    {
        "text": "uat",
        "value": "uat",
    },
    {
        "text": "prod",
        "value": "prod",
    },
];

export default config;
