import {useState} from "react";
import {AIMType} from "../types/aim";

export const useAim = (globalVariableName: string) => {
    const callAim = (globalVariableName: string) =>
        window[globalVariableName as any] as unknown as AIMType;
    const [error, setError] = useState(undefined);
    const [ready, setReady] = useState(false);
    const [sessionId, setSessionId] = useState("not yet loaded");
    const [signal, setSignal] = useState("not yet loaded" as any);

    const getSessionId = () => {
        try {
            setSessionId(callAim(globalVariableName).getSessionId());
        } catch (e) {
            setSessionId(`Error: ${e}`);
        }
    };
    const getSignal = () => {
        try {
            callAim(globalVariableName).ondetect((json: {}) => {
                setSignal(json);
            });
        } catch (e) {
            setSignal(`Error: ${e}`);
        }
    };
    const awaitOndetect = () => {
        return new Promise<void>((resolve, reject) => {
            function check() {
                if (
                    typeof callAim(globalVariableName).ondetect === "function"
                ) {
                    clearInterval(intervalCheck);
                    resolve();
                }
            }
            const intervalCheck = setInterval(check, 100);
        });
    };
    awaitOndetect()
        .then(() => {
            getSignal();
            getSessionId();
        })
        .catch(e => {
            setError(e.toString());
        })
        .then(() => {
            setReady(true);
        });
    return {
        "aim": globalVariableName,
        error,
        ready,
        sessionId,
        signal,
    };
};
