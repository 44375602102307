import * as React from "react";
import {useEffect, useState} from "react";
import {Header} from "semantic-ui-react";
import {useAim} from "../hooks/use_aim";
import {CodeBox} from "./CodeBox";
import {InfoPane} from "./InfoPane";

export type AIMResultsPaneProps = {
    "apiKeys": string[];
    "globals": string[];
};

export const AIMResultsPane = (props: AIMResultsPaneProps) => {
    const snippets = props.globals.map(useAim);

    const [allReady, setReady] = useState(false);

    useEffect(() => {
        setReady(
            snippets.reduce((acc, {ready}) => ready && acc, true as boolean)
        );
    }, [snippets]);

    return (
        <div>
            {snippets.map(({aim, error, sessionId, signal}) => (
                <React.Fragment key={aim}>
                    <Header as="h2">
                        Results ({aim}){error ? `: (Error: ${error})` : ""}
                    </Header>
                    <CodeBox
                        testId="resultsPane"
                        contents={JSON.stringify(
                            {sessionId, signal},
                            null,
                            "\t"
                        )}
                        modified={signal !== "not yet loaded"}
                    />
                </React.Fragment>
            ))}
            {allReady && <InfoPane apiKeys={props.apiKeys} />}
        </div>
    );
};

export default AIMResultsPane;
