import {routes} from "../routes";
import {aimReaderMultiGlobalWithAttemptedDetection} from "../snippets/aim_reader_multi_global_with_attempted_detection";
import {aimReaderMultiGlobalWithGlobal} from "../snippets/aim_reader_multi_global_with_global";
import {aimReaderMultiGlobalWithOrgId} from "../snippets/aim_reader_multi_global_with_org_id";
import {aimReaderMultiOrgIdWithGlobal} from "../snippets/aim_reader_multi_org_id_with_global";
import {aimReaderMultiOrgIdWithOrgId} from "../snippets/aim_reader_multi_org_id_with_org_id";

export const legacyReaderMultiPages = [
    {
        "route": routes.html[300],
        "script": aimReaderMultiGlobalWithOrgId,
    },
    {
        "route": routes.html[301],
        "script": aimReaderMultiOrgIdWithGlobal,
    },
    {
        "route": routes.html[302],
        "script": aimReaderMultiOrgIdWithOrgId,
    },
    {
        "route": routes.html[380],
        "script": aimReaderMultiGlobalWithAttemptedDetection,
    },
    {
        "route": routes.html[381],
        "script": aimReaderMultiGlobalWithGlobal,
    },
];
