import {useEffect} from "react";

export type ScriptProps = {
    "asyncAttribute"?: boolean;
    "noModule"?: boolean;
    "script"?: string;
    "src"?: string;
    "typeModule"?: boolean;
    "integrity"?: string;
    "crossOrigin"?: string;
};

export const useScript = ({
    asyncAttribute = true,
    noModule = false,
    script = "",
    src,
    typeModule = false,
    integrity,
    crossOrigin,
}: ScriptProps) => {
    useEffect(() => {
        const scriptElement = document.createElement("script");
        if (src) {
            scriptElement.src = src;
        } else {
            scriptElement.innerHTML = script;
        }

        scriptElement.async = asyncAttribute;
        if (typeModule) {
            scriptElement.type = "module";
        }
        if (noModule) {
            scriptElement.noModule = noModule;
        }
        if (integrity) {
            scriptElement.integrity = integrity;
        }
        if (crossOrigin) {
            scriptElement.crossOrigin = crossOrigin;
        }

        document.body.appendChild(scriptElement);

        return () => {
            document.body.removeChild(scriptElement);
        };
    }, [
        asyncAttribute,
        noModule,
        script,
        src,
        typeModule,
        integrity,
        crossOrigin,
    ]);
};
