import axios from "axios";
import CodeBox from "./../CodeBox";
import {SnippetConfig} from "../../types/snippet_config";

interface AIMReaderSlimSignalScriptProps {
    "config": SnippetConfig;
}

const AIMReaderSlimSignalScript = ({
    config,
}: AIMReaderSlimSignalScriptProps): JSX.Element => {
    const messageSnippet = "`GET+${apiPath}/${aimId}`";
    const pathSnippet =
        "`${apiPath}/${aimId}?data=${encodedPayload}&signature=${encodedSignature}`";
    const bodySnippet = "await httpGet(`${apiHost}${path}`)";

    const createScriptText = () => {
        return `
import { createHmac } from "crypto";
import { Request, Response } from "express";
import { AsyncRouter } from "express-async-router";
import * as https from "https";

const router = AsyncRouter();

const httpGet = url => {
    return new Promise((resolve, reject) => {
        https.get(url, (res) => {
            res.setEncoding("utf8");
            let body = ""; 
            res.on("data", chunk => body += chunk);
            res.on("end", () => resolve(body));
        }).on("error", reject);
    });
};

router.get("/", async (req: Request, res: Response) => {

    const apiHost = "${config.host}";
    const apiPath = "/api/v1.0/hcp";
    const aimId = 10;
    const key = "sup3rs3cr3t!!";
    const message = ${messageSnippet};
    const hmac = createHmac("sha256", key);
    hmac.setEncoding("hex"); 
    hmac.write(message);
    hmac.end(); 

    const signature = hmac.read();
    const timestamp = new Date();
    const payload = JSON.stringify({
        "aimid": aimId, 
        "timestamp": timestamp.getTime()
    });

    const encodedPayload = Buffer.from(payload).toString("base64");
    const encodedSignature = Buffer.from(signature).toString("base64");
    const path = ${pathSnippet};
    const body = ${bodySnippet};

    res.setHeader("Content-Type", "application/json");
    res.send(JSON.stringify(body, null, 4));
});

export const SlimSignalTesterHandler = router;
        `;
    };

    axios.get("/api/v1/slim-signal-tester").then(result => {
        console.log(result.data);
    });

    return <CodeBox testId="resultsPane" contents={`${createScriptText()}`} />;
};

export default AIMReaderSlimSignalScript;
