import * as React from "react";
import AIMReaderSlimSignalScript from "./html/AIMReaderSlimSignalScript";
import Container from "@mui/material/Container";
import {routes} from "../routes";
import {SnippetConfig} from "../types/snippet_config";
import {styled} from "@mui/material/styles";

const Header = styled("h1")(({theme}) => ({
    "fontSize": "2em",
    "margin": theme.spacing(1, 0),
}));

interface SlimSignal410Props {
    "config": SnippetConfig;
}

const SlimSignal410 = ({config}: SlimSignal410Props): JSX.Element => {
    return (
        <Container data-testid="container">
            <Header data-testid="header">
                {config.version} Slim Signal: {routes.html[410].title} (
                {config.env})
            </Header>
            <AIMReaderSlimSignalScript config={config} />
        </Container>
    );
};

export default SlimSignal410;
