export const aimTagWithPageview = (
    apiKey: string,
    dataLayerName = "aimDataLayer"
) => {
    return `
window.${dataLayerName} = window.${dataLayerName} || [];
function aimTag(){${dataLayerName}.push(arguments);}
aimTag('${apiKey}', 'pageview');
`;
};
