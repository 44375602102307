import React, {useState} from "react";
import {Cookies} from "react-cookie";
import CodeBox from "./CodeBox";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import {routes} from "../routes";
import {SnippetConfig} from "../types/snippet_config";
import {styled} from "@mui/material/styles";

const Header = styled("h1")(({theme}) => ({
    "fontSize": "2em",
    "margin": theme.spacing(1, 0),
}));

const Header3 = styled("h3")(({theme}) => ({
    "margin": theme.spacing(1, 0),
}));

interface TagDeleting500Props {
    "config": SnippetConfig;
}

const TagDeleting500 = ({config}: TagDeleting500Props): JSX.Element => {
    const cookies: Cookies = new Cookies();
    const dmdCookiesClearSrc = `https:${config.host}${config.dmdCookies.clear}`;
    const dmdCookiesReadSrc = `https:${config.host}${config.dmdCookies.read}`;
    const dmdStorageClearSrc = `https:${config.host}${config.dmdStorage.clear}`;
    const dmdStorageReadSrc = `https:${config.host}${config.dmdStorage.read}`;

    const [clientCookieTagId, setClientCookieTagId] = useState(
        cookies.get(config.clientCookieTagKey)
    );
    const [localStorageTagId, setLocalStorageTagId] = useState(
        localStorage.getItem(config.localStorageTagKey) || undefined
    );
    const [windowName, setWindowName] = useState(window.name || undefined);
    const [clientCookieSessionId, setClientCookieSessionId] = useState(
        cookies.get(config.clientCookieSessionKey)
    );
    const [dmdCookiesRead, setDmdCookiesRead] = useState(dmdCookiesReadSrc);
    const [dmdStorageRead, setDmdStorageRead] = useState(dmdStorageReadSrc);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const form = event.target as HTMLFormElement;

        const clientCookieTag = form.elements.namedItem(
            "clientCookieTag"
        ) as HTMLInputElement;
        if (clientCookieTag.checked) {
            cookies.remove(config.clientCookieTagKey, {"path": "/"});
            setClientCookieTagId(undefined);
        }

        const localStorageTag = form.elements.namedItem(
            "localStorageTag"
        ) as HTMLInputElement;
        if (localStorageTag.checked) {
            localStorage.removeItem(config.localStorageTagKey);
            setLocalStorageTagId(undefined);
        }

        const windowName = form.elements.namedItem(
            "windowName"
        ) as HTMLInputElement;
        if (windowName.checked) {
            window.name = "";
            setWindowName(undefined);
        }

        const clientCookieSession = form.elements.namedItem(
            "clientCookieSession"
        ) as HTMLInputElement;
        if (clientCookieSession.checked) {
            cookies.remove(config.clientCookieSessionKey, {"path": "/"});
            setClientCookieSessionId(undefined);
        }

        const dmdCookies = form.elements.namedItem(
            "dmdCookies"
        ) as HTMLInputElement;
        if (dmdCookies.checked) {
            const clearCookiesRequest = new Image();
            clearCookiesRequest.onload = function () {
                setDmdCookiesRead(`${dmdCookiesReadSrc}?${Date.now()}`);
            };
            clearCookiesRequest.src = `${dmdCookiesClearSrc}?${Date.now()}`;
        }

        const dmdStorage = form.elements.namedItem(
            "dmdStorage"
        ) as HTMLInputElement;
        if (dmdStorage.checked) {
            const clearStorageRequest = document.createElement("iframe");
            clearStorageRequest.style.display = "none";
            clearStorageRequest.onload = function () {
                setDmdStorageRead(`${dmdStorageReadSrc}?${Date.now()}`);
            };
            clearStorageRequest.src = `${dmdStorageClearSrc}?${Date.now()}`;
            document.body.appendChild(clearStorageRequest);
        }
    };

    return (
        <Container data-testid="container">
            <Header data-testid="header">
                {config.version} Tag Deleting: {routes.html[500].title} (
                {config.env})
            </Header>
            <form onSubmit={handleSubmit} data-testid="form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={true}
                                    name="clientCookieTag"
                                />
                            }
                            label="Delete Client Cookie Tag"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={true}
                                    name="localStorageTag"
                                />
                            }
                            label="Delete Client Local Storage Tag"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={true}
                                    name="windowName"
                                />
                            }
                            label="Delete Client Window Name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={true}
                                    name="clientCookieSession"
                                />
                            }
                            label="Delete Client Cookie Session"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={true}
                                    name="dmdCookies"
                                />
                            }
                            label="Delete DMD Cookies"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={true}
                                    name="dmdStorage"
                                />
                            }
                            label="Delete DMD Storage"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            data-testid="deleteButton"
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <hr />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Header3>
                        Client Cookie Tag ({config.clientCookieTagKey})
                    </Header3>
                    <CodeBox
                        testId="resultsPane_clientCookieTagId"
                        contents={`${clientCookieTagId}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Header3>
                        Client Local Storage Tag ({config.localStorageTagKey})
                    </Header3>
                    <CodeBox
                        testId="resultsPane_localStorageTagId"
                        contents={`${localStorageTagId}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Header3>Client Window Name</Header3>
                    <CodeBox
                        testId="resultsPane_windowName"
                        contents={`${windowName}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Header3>
                        Client Cookie Session ({config.clientCookieSessionKey})
                    </Header3>
                    <CodeBox
                        testId="resultsPane_clientCookieSessionId"
                        contents={`${clientCookieSessionId}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Header3>DMD Cookies</Header3>
                    <iframe
                        src={`${dmdCookiesRead}`}
                        title="DMD Cookies"
                        width="100%"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Header3>DMD Storage</Header3>
                    <iframe
                        src={`${dmdStorageRead}`}
                        title="DMD Storage"
                        width="100%"
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default TagDeleting500;
