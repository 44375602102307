import urlRedirectTransformers from "aim-web-url-transformer";
import {config} from "./config";
import {getBookEnds} from "./get_book_ends";
import {md5Hash} from "./md5_hash";
import {querystring} from "../url/querystring";

const {urlRedirectEncoder, urlRedirectStripTokens} = urlRedirectTransformers(
    config.leftBracket,
    config.rightBracket
);

const getKey = (target: string) => getBookEnds(4)(md5Hash(target));
const getKeySalt = ({property = "", element = ""}) => property + "-" + element;

export const generateRedirectUrl = ({
    baseUrl,
    "deployment_id": deploymentId,
    element,
    email,
    "esp_id": espId,
    id,
    keycode,
    "launch_id": launchId,
    pattern,
    property,
    sid,
    targetUrl,
}: {
    "baseUrl": string;
    "deployment_id": string;
    "element"?: string;
    "email": string;
    "esp_id": number;
    "id": string;
    "keycode": string;
    "launch_id": string;
    "pattern": string;
    "property"?: string;
    "sid": string;
    "targetUrl": string;
}) => {
    const key = getKey(
        "AIM-" +
            urlRedirectStripTokens(pattern)(targetUrl) +
            getKeySalt({element, property})
    );
    const deployed = Math.round(Date.now() / 1000);
    const data = keycode + "+" + sid;
    const click = urlRedirectEncoder(pattern)(targetUrl);
    const queryData = [
        {key},
        {id},
        {email},
        {"launch_id": launchId},
        {"esp_id": espId},
        {deployed},
        {"deployment_id": deploymentId},
        {data},
        {click},
    ];
    return baseUrl + querystring(queryData);
};
