import React from "react";
import {Input, InputProps} from "semantic-ui-react";

export const TextInput = ({
    fluid = true,
    onChange,
    ...props
}: {
    "fluid"?: boolean;
    "onChange": (value: string) => void;
} & Omit<InputProps, "onChange">) => {
    return (
        <Input
            {...props}
            fluid={fluid}
            onChange={(event, {value}) => onChange(String(value))}
        />
    );
};
