// tslint:disable:max-line-length
export const aimReaderInitPageview = (config: {
    "apiKey": string;
    "env": string;
    "host": string;
}) => `
(function(w,d,s,m,n,t){
    w[m]=w[m]||{init:function(){(w[m].q=w[m].q||[]).push(arguments);},ready:function(c){if('function'!=typeof c){return;}n.onload=n.onreadystatechange=function(){
    if(!n.readyState||/loaded|complete/.test(n.readyState)){n.onload=n.onreadystatechange=null;if(t.parentNode&&n.parentNode){t.parentNode.removeChild(n);}if(c){c();}}};}},
    w[m].d=1*new Date();n=d.createElement(s);t=d.getElementsByTagName(s)[0];n.async=1;n.src='${
    config.host
}/javascript/beacon.js?v3.4.0';
    ${
    config.env === "prod"
        ? ""
        : `n.setAttribute("data-sub",'${config.env}');`
}n.setAttribute("data-aim",m);t.parentNode.insertBefore(n,t);
})(window,document,'script','AIM');

AIM.init('${config.apiKey}', {'onload_pageview': false });

AIM.ready(function(){
    // we will manually set our pageview and inject some data of our own
    var url = window.location.href;
    var title = 'Testing onload_pageview ' + document.title;
    var referrer = document.referrer;
    // if current page serves only as a landing page for campaigned visitors
    url += '#testing';  // <-- Feel free to add any additional data by appending to url string

    AIM.pageview(url, title, referrer);

    AIM.ondetect(function(json){
        console.log('AIM.ondetect: anonymous func called', json);
    });
});
`;
// tslint:enable:max-line-length
export default aimReaderInitPageview;
