import {ArrayElement} from "../../types/utility/array_element";

export const commandTypes = [
    "pageview",
    "session",
    "signal",
    "transition",
    "authenticate",
    "impersonate",
    "clear",
];

export type v4CommandTypes = ArrayElement<typeof commandTypes>;
