import {useEffect, useState} from "react";
import {recordConsole} from "../services/record_console";
import {AIMType} from "../types/aim";

export const useAimInfo = (apiKey?: string) => {
    const [output, setOutput] = useState("");

    const callAim = (globalVariableName: string) =>
        window[globalVariableName as any] as unknown as AIMType;

    const parseDebug = (debugResults: string[][], errors: string[][] = []) => {
        try {
            const isFound = (param: string) =>
                param.startsWith("AIM Reader Detected");
            return errors.length > 0
                ? `Error Returned: ${errors[0][0]}\n`
                : debugResults
                    .map(log => log.filter(isFound).join(""))
                    .filter(isFound)
                    .join("") || "Test Error: AIM Reader not detected\n";
        } catch (e) {
            return `Test Error: AIM Reader not detected (${e})\n`;
        }
    };
    useEffect(() => {
        try {
            const log = recordConsole();
            const error = recordConsole("error");
            callAim("AIM").info(apiKey);
            const results = log.finish();
            const errors = error.finish();
            setOutput(parseDebug(results, errors));
        } catch (e) {
            setOutput(`Error: ${e}\n`);
        }
    }, [apiKey]);

    return {
        apiKey,
        output,
    };
};
