import {useFormik} from "formik";
import React from "react";
import {routes} from "../routes";
import {generateRedirectUrl} from "../services/tag_on_click_generator/generate_redirect_url";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BoldTypography = styled(Typography)(({theme}) => ({
    "fontWeight": theme.typography.fontWeightBold,
}));

interface TagWriting120Props {
    "env": string;
    "host": string;
    "version": string;
}

export const TagWriting120 = ({
    env,
    host,
    version,
}: TagWriting120Props): JSX.Element => {
    const title = `${version} Tag Writing: ${routes.html[120].title} (${env})`;
    const initialValues = {
        "baseUrl": `${host}/toc/link/`,
        "dgid": "U12345",
        "deploymentId": "0",
        "element": "",
        "emailAddress": "qa@dmdconnects.com",
        "espId": 71,
        "keycode": "",
        "launchId": "57804202",
        "property": "",
        "sid": "",
        "targetUrl": `${window.location.origin}/html/custom_pageview_modern_build_only.html`,
    };

    const onSubmit = () => {};

    const formik = useFormik({
        initialValues,
        onSubmit,
    });

    const redirect = generateRedirectUrl({
        "baseUrl": formik.values.baseUrl,
        "deployment_id": formik.values.deploymentId,
        "element": formik.values.element,
        "email": formik.values.emailAddress,
        "esp_id": formik.values.espId,
        "id": formik.values.dgid,
        "keycode": formik.values.keycode,
        "launch_id": formik.values.launchId,
        "pattern": "$^",
        "property": formik.values.property,
        "sid": formik.values.sid || formik.values.dgid,
        "targetUrl": formik.values.targetUrl,
    });

    return (
        <Container sx={{"marginBottom": "3em"}} data-testid="container">
            <BoldTypography variant="h4" data-testid="title">
                {title}
            </BoldTypography>
            <TextField
                label="DGID"
                name="dgid"
                value={formik.values.dgid}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                data-testid="dgid-input"
            />
            <TextField
                label="Email Address"
                name="emailAddress"
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                data-testid="email-input"
            />
            <TextField
                label="Target Url"
                name="targetUrl"
                value={formik.values.targetUrl}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                data-testid="target-url-input"
            />
            <Accordion data-testid="accordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    data-testid="accordion-summary"
                >
                    Additional Options
                </AccordionSummary>
                <AccordionDetails data-testid="accordion-details">
                    <TextField
                        label="Base URL"
                        name="baseUrl"
                        value={formik.values.baseUrl}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        data-testid="base-url-input"
                    />
                    <TextField
                        label="Deployment ID"
                        name="deploymentId"
                        value={formik.values.deploymentId}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        data-testid="deployment-id-input"
                    />
                    <TextField
                        label="ESP ID"
                        name="espId"
                        value={formik.values.espId}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        data-testid="esp-id-input"
                    />
                    <TextField
                        label="Keycode"
                        name="keycode"
                        value={formik.values.keycode}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        data-testid="keycode-input"
                    />
                    <TextField
                        label="Launch ID"
                        name="launchId"
                        value={formik.values.launchId}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        data-testid="launch-id-input"
                    />
                    <TextField
                        label="SID"
                        name="sid"
                        value={formik.values.sid}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        data-testid="sid-input"
                    />
                    <TextField
                        label="Element ID"
                        name="element"
                        value={formik.values.element}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        data-testid="element-id-input"
                    />
                    <TextField
                        label="Property ID"
                        name="property"
                        value={formik.values.property}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        data-testid="property-id-input"
                    />
                </AccordionDetails>
            </Accordion>
            <a href={redirect} data-testid="redirect-link">
                {redirect}
            </a>
        </Container>
    );
};
