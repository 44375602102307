import * as React from "react";
import {Container, Header} from "semantic-ui-react";
import {config as v2Config} from "../../config/aim_reader";
import {config as v4Config} from "../../config/aim_tag";
import {getGlobalsFromSnippet} from "../../services/get_globals_from_snippet";
import {aimTagClient} from "../../snippets/aim_tag_client";
import {Environment} from "../../types/environment";
import {SnippetConfig} from "../../types/snippet_config";
import {AddScriptTag} from "../AddScriptTag";
import AIMResultsPane from "../AIMResultsPane";
import CodeBox from "../CodeBox";
import ExternalCodeBox from "../ExternalCodeBox";

interface Props {
    "dataLayerName"?: string;
    "env": Environment;
    "title": string;
    "v2Script": (config: SnippetConfig) => string;
    "v4Script": (apiKey: string, dataLayerName?: string) => string;
}

export const ParallelSnippetTestPage = ({
    dataLayerName,
    env,
    title,
    v2Script,
    v4Script,
}: Props): JSX.Element => {
    const apiKeys = [v2Config[env].apiKey];
    const aimReaderScriptText = v2Script(v2Config[env]);
    const clientUrl = aimTagClient(v4Config[env].host, dataLayerName);
    const aimTagScriptText = v4Script(v4Config[env].apiKey, dataLayerName);
    const completeTitle = `Tag Reading: ${title} (${env})`;

    return (
        <Container>
            <AddScriptTag script={aimReaderScriptText} />
            <AddScriptTag script={aimTagScriptText} />
            <Header as="h1">{completeTitle}</Header>
            <CodeBox testId="readerSnippet" contents={aimReaderScriptText} />
            <ExternalCodeBox
                testId="tagSnippet"
                externalScript={clientUrl}
                inlineScript={aimTagScriptText}
            />
            <AIMResultsPane
                apiKeys={apiKeys}
                globals={getGlobalsFromSnippet(aimReaderScriptText)}
            />
        </Container>
    );
};
