import * as React from "react";
import FixedHeader from "./FixedHeader";
import Footer from "./Footer";
import Main from "./Main";
import {Collapsible} from "../containers/Collapsible";

class FixedMenuLayout extends React.Component {
    render() {
        return (
            <div>
                <FixedHeader />
                <Main />
                <Collapsible
                    style={{"bottom": 0, "position": "fixed", "zIndex": 100}}
                >
                    <Footer />
                </Collapsible>
            </div>
        );
    }
}

export default FixedMenuLayout;
