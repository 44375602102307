export const aimTagWithSignal = (
    apiKey: string,
    dataLayerName = "aimDataLayer"
) => {
    return `
window.${dataLayerName} = window.${dataLayerName} || [];
function aimTag(){${dataLayerName}.push(arguments);}
aimTag('${apiKey}', 'signal', function(err, success) {
        if (err) {
            console.log(err);
        } else {
            console.log(success);
        }
    }
);
`;
};
