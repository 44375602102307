import {Cookies} from "react-cookie";
import {config as aimReader} from "../config/aim_reader";
import {config as aimTag} from "../config/aim_tag";
import {config as prebid} from "../config/prebid";
import {Environment} from "../types/environment";
import {parseQueryParams} from "../services/parse_query_params";
import {Version} from "../types/version";
import appendQueryParams from "../services/appendQueryParams";

type QueryParams = {"env": Environment; "version": Version};
const validVersions: Version[] = ["aim_reader", "aim_tag"];
const validEnvs: Environment[] = [
    "dev",
    "local",
    "prod",
    "qa",
    "sbx",
    "stg",
    "test",
    "uat",
];
function isVersion(arg: any): arg is Version {
    return validVersions.includes(arg);
}
function isEnvironment(arg: any): arg is Environment {
    return validEnvs.includes(arg);
}

class EnvironmentSelector {
    cookie: Cookies;

    constructor() {
        this.cookie = new Cookies();
    }

    getConfig = (version = this.getVersion()) => {
        const env = this.getEnvironment();
        return "aim_reader" === version ? aimReader[env] : aimTag[env];
    };

    getDefaultEnvironment = (): Environment => {
        return this.validateEnvironment(process.env.REACT_APP_ENV) || "local";
    };

    getDefaultVersion = (): Version => "aim_reader";

    getQueryParams = () =>
        parseQueryParams(window.location.search) as QueryParams;

    getEnvironment = (): Environment => {
        const queryParams = this.getQueryParams();
        const envCookie = this.cookie.get("aim-suite-env") as Environment;
        const env =
            this.validateEnvironment(queryParams.env) ||
            this.validateEnvironment(envCookie) ||
            this.getDefaultEnvironment();
        this.setEnvironment(env);
        return env;
    };

    getPrebidConfig = () => {
        const env = this.getEnvironment();
        const config = prebid[env];
        config.env = env;
        return config;
    };

    getVersion = (): Version => {
        const queryParams = this.getQueryParams();
        const versionCookie = this.cookie.get("aim-suite-version") as Version;
        const version =
            this.validateVersion(queryParams.version) ||
            this.validateVersion(versionCookie) ||
            this.getDefaultVersion();
        this.setVersion(version);
        return version;
    };

    setEnvironment = (value: Environment) => {
        const queryParams = this.getQueryParams();
        this.cookie.set("aim-suite-env", value, {
            "path": "/",
            "sameSite": "lax",
        });
        if (queryParams.env !== value) {
            this.setQueryParams("env", value);
        }
    };

    setVersion = (value: Version) => {
        const queryParams = this.getQueryParams();
        this.cookie.set("aim-suite-version", value, {
            "path": "/",
            "sameSite": "lax",
        });
        if (queryParams.version !== value) {
            this.setQueryParams("version", value);
        }
    };

    setQueryParams(key: "env", value: Environment): void;
    setQueryParams(key: "version", value: Version): void;
    setQueryParams(key: "env" | "version", value: Environment | Version) {
        const url = appendQueryParams(window.location.href, {
            ...parseQueryParams(window.location.search),
            [key]: value,
        });
        window.history.replaceState(window.history.state, "", url);
    }

    validateEnvironment = (value: any) => {
        const normalizedEnv =
            typeof value === "string" ? value.trim().toLowerCase() : "";
        return isEnvironment(normalizedEnv) && normalizedEnv;
    };

    validateVersion = (value: any) => {
        const normalizedVersion =
            typeof value === "string" ? value.trim().toLowerCase() : "";
        return isVersion(normalizedVersion) && normalizedVersion;
    };
}
export default EnvironmentSelector;
