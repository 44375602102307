import React, {useState} from "react";
import {Container, Form, Grid, Header} from "semantic-ui-react";
import {routes} from "../routes";
import aimEmailOpen from "../snippets/aim_email_open_cross_tag";
import CodeBox from "./CodeBox";
import {ExampleAuthenticationIDList} from "./html/ExampleAuthenticationIDList";
import {createImage} from "../services/dom/create_image";

interface TagWriting110Props {
    "env": string;
    "host": string;
    "version": string;
}

const TagWriting110 = ({
    env,
    host,
    version,
}: TagWriting110Props): JSX.Element => {
    const [formValues, setFormValues] = useState({
        "dgid": "U12345",
        "dgidCrossTag": "U084309121",
        "campaignCode": "999",
        "submitted": false,
    });

    const handleReset = (event: React.FormEvent) => {
        event.preventDefault();
        window.location.reload();
    };

    const attachCrossTaggingImages = async ({
        campaignCode,
        dgid,
        dgidCrossTag,
        host,
        parent,
    }: {
        "campaignCode": string;
        "dgid": string;
        "dgidCrossTag": string;
        "host": string;
        "parent": HTMLElement;
    }) => {
        const getBrokenImageFromError = ({path}: {"path": any}) => path[0];

        parent.append(
            await createImage(
                `${host}/_icu.png?dgid=${dgid}&campaign=${campaignCode}`
            ).catch(getBrokenImageFromError)
        );
        parent.append(
            await createImage(
                `${host}/_icu.png?dgid=${dgidCrossTag}&campaign=${campaignCode}`
            ).catch(getBrokenImageFromError)
        );
        parent.append(await createImage(`${host}/_itu.png?`));
        parent.append(await createImage(`${host}/_itu.png?${Date.now()}`));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const target = event.target as HTMLFormElement;
        const dgid = target.elements.namedItem("dgid") as HTMLInputElement;
        const dgidCrossTag = target.elements.namedItem(
            "dgidCrossTag"
        ) as HTMLInputElement;
        const campaignCode = target.elements.namedItem(
            "campaignCode"
        ) as HTMLInputElement;

        const newFormValues = {...formValues};

        if ("" !== dgid.value.trim()) {
            newFormValues.dgid = dgid.value;
        }
        if ("" !== dgidCrossTag.value.trim()) {
            newFormValues.dgidCrossTag = dgidCrossTag.value;
        }

        newFormValues.campaignCode = campaignCode.value;
        newFormValues.submitted = true;

        setFormValues(newFormValues);

        const submit = (event.target as HTMLFormElement).elements.namedItem(
            "submit"
        ) as HTMLButtonElement;
        submit.disabled = true;

        const renderedHtml =
            document.getElementById("renderedHtml") || document.body;

        attachCrossTaggingImages({
            "campaignCode": campaignCode.value,
            "dgid": dgid.value,
            "dgidCrossTag": dgidCrossTag.value,
            host,
            "parent": renderedHtml,
        }).catch(err => {
            (renderedHtml || document).append(
                `An error occurred: ${JSON.stringify(err)}`
            );
        });
    };

    const scriptText = formValues.submitted
        ? aimEmailOpen(host, formValues)
        : "";

    return (
        <Container text data-testid="container">
            <Header as="h1" data-testid="header">
                {`${version} Tag Writing: ${routes.html[110].title} (${env})`}
            </Header>
            <Form
                onSubmit={handleSubmit}
                onReset={handleReset}
                data-testid="form"
            >
                <Form.Group widths="equal">
                    <Form.Input
                        data-testid="dgidInput"
                        fluid
                        label="dgid"
                        name="dgid"
                        required
                        defaultValue={formValues.dgid}
                    />
                    <Form.Input
                        data-testid="dgidCrossTagInput"
                        fluid
                        label="dgidCrossTag"
                        name="dgidCrossTag"
                        required
                        defaultValue={formValues.dgidCrossTag}
                    />
                    <Form.Input
                        data-testid="campaignCodeInput"
                        fluid
                        label="Campaign Code"
                        name="campaignCode"
                        defaultValue={formValues.campaignCode}
                    />
                </Form.Group>
                <Form.Group data-testid="form-group">
                    <Form.Button
                        primary
                        name="submit"
                        type="submit"
                        data-testid="tagButton"
                    >
                        Tag
                    </Form.Button>
                    <Form.Button
                        secondary
                        name="reset"
                        type="reset"
                        data-testid="resetButton"
                    >
                        Reset Form
                    </Form.Button>
                </Form.Group>
            </Form>
            <Header as="h2" data-testid="html-header">
                HTML
            </Header>
            <p data-testid="note">
                Note: Intentionally cache busting _itu.png to force 2 requests
            </p>
            <CodeBox testId="emailSnippet" contents={scriptText} />
            <Grid columns={2} divided data-testid="grid">
                <Grid.Row data-testid="grid-row">
                    <Grid.Column data-testid="grid-column-1">
                        <Header as="h2" data-testid="rendered-html-header">
                            Rendered HTML
                        </Header>
                        <div data-testid="renderedHtml" id="renderedHtml" />
                    </Grid.Column>
                    <Grid.Column data-testid="grid-column-2">
                        <ExampleAuthenticationIDList data-testid="example-authentication-id-list" />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default TagWriting110;
