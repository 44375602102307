import * as React from "react";
import {
    Container,
    Divider,
    Form,
    Grid,
    Header,
    List,
    Segment,
} from "semantic-ui-react";
import {SnippetConfig} from "../../../types/snippet_config";
import {AddScriptTag} from "../../AddScriptTag";
import ExternalCodeBox from "../../ExternalCodeBox";
import {aimTagClient} from "../../../snippets/aim_tag_client";
import {TextInput} from "../../form/text_input";
import {Checkbox} from "../../form/checkbox";

interface State {
    "apiKey": string;
    "dataLayerName": string;
    "autoDismissSeconds": number | undefined;
    "logoUrl": string;
    "title": string;
    "runScript": string;
}

export const AIMVerifyPage = ({
    config,
    script,
    pageTitle,
    modernBuildOnly = true,
}: {
    "config": SnippetConfig;
    "script": (state: any) => string;
    "pageTitle": string;
    "modernBuildOnly"?: boolean;
}) => {
    const [state, setState] = React.useState<State>({
        "apiKey": config.apiKey,
        "dataLayerName": "aimDataLayer",
        "autoDismissSeconds": undefined,
        "logoUrl":
            "https://cdn.worldvectorlogo.com/logos/glaxosmithkline-logo-1.svg",
        "title": "My GSK Brand",
        "runScript": "false",
    });

    const {
        apiKey,
        dataLayerName,
        autoDismissSeconds,
        logoUrl,
        title,
        runScript,
    } = state;
    const scriptParams = {
        apiKey,
        dataLayerName,
        autoDismissSeconds,
        logoUrl,
        title,
    };

    const runScriptBoolean = runScript === "true";
    const noSupport = "\n// No integration for Legacy AIM Reader\n";
    const clientUrl =
        config.version === "v4" ? aimTagClient(config.host, dataLayerName) : "";
    const aimTagScriptText =
        config.version === "v4" ? script(scriptParams) : noSupport;
    const completeTitle = `${pageTitle} (${config.env})`;
    const updateState =
        (name: keyof State, transform: (value: any) => any = v => v) =>
            (value: any) =>
                setState(prevState => ({...prevState, [name]: transform(value)}));

    return (
        <Container>
            {runScriptBoolean && (
                <AddScriptTag
                    script={aimTagScriptText}
                    asyncAttribute={false}
                />
            )}
            <Header as="h1">{completeTitle}</Header>
            <Grid divided={true}>
                <Grid.Row>
                    <Grid.Column width="11">
                        <Header as="h2">JavaScript Integration</Header>
                        <ExternalCodeBox
                            testId="tagSnippet"
                            externalScript={clientUrl}
                            inlineScript={aimTagScriptText}
                            runScript={runScriptBoolean}
                            modernBuildOnly={modernBuildOnly}
                        />
                    </Grid.Column>
                    <Grid.Column width="5">
                        <Header as="h2">Interactive Element</Header>
                        <Checkbox
                            label="Run Script"
                            checked={runScriptBoolean}
                            onChange={updateState("runScript", v => String(v))}
                        />
                        <Container style={{"margin": "10px 0"}}>
                            <TextInput
                                label="API Key"
                                defaultValue={apiKey}
                                onChange={updateState("apiKey")}
                            />
                            <TextInput
                                label="Data Layer Name"
                                defaultValue={dataLayerName}
                                onChange={updateState("dataLayerName")}
                            />
                            <TextInput
                                label="Auto Dismiss Seconds"
                                defaultValue={autoDismissSeconds}
                                onChange={updateState("autoDismissSeconds", v =>
                                    Number(v)
                                )}
                            />
                            <TextInput
                                label="Logo URL"
                                defaultValue={logoUrl}
                                onChange={updateState("logoUrl")}
                            />
                            <TextInput
                                label="Title"
                                defaultValue={title}
                                onChange={updateState("title")}
                            />
                        </Container>
                        <Form.Button
                            id="interactiveElement"
                            primary={true}
                            name="submit"
                            type="submit"
                            data-testid="interactiveElement"
                        >
                            {"Click Me"}
                        </Form.Button>
                        <Header as="h3">Interactive Results</Header>
                        <Segment
                            id="interactiveResults"
                            style={{"overflow": "auto", "whiteSpace": "pre"}}
                        ></Segment>
                        <Divider inverted={true} section={true} />
                        <Header as="h2">Emails</Header>
                        <List divided={true} relaxed={true}>
                            <List.Item>
                                <List.Icon
                                    name="user md"
                                    size="large"
                                    verticalAlign="middle"
                                />
                                <List.Content>
                                    <List.Header>
                                        fake@dmdconnects.com
                                    </List.Header>
                                    <List.Description>[UNK]</List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon
                                    name="user md"
                                    size="large"
                                    verticalAlign="middle"
                                />
                                <List.Content>
                                    <List.Header>
                                        jeffrey.anderson@atlanticare.org
                                    </List.Header>
                                    <List.Description>
                                        U080669619 [TST]
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
