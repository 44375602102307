import qs from "qs";
import {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {parseQueryParams} from "../services/parse_query_params";

export const useQueryParamStore = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Parse query parameters
    const params = parseQueryParams(location.search);

    // If version is not provided, set a default value.
    const defaultVersion = "aim_tag";
    const defaultEnv = "qa";
    params.version = params.version || defaultVersion;
    params.env = params.env || defaultEnv;

    const [state, setState] = useState(params);

    useEffect(() => {
        navigate(
            {
                "hash": location.hash,
                "search": qs.stringify(state),
            },
            {
                "replace": true,
            }
        );
    }, [state, location.hash, navigate]);

    return [state, setState] as const;
};
