import {routes} from "../routes";
import {aimReaderInitOndetect} from "../snippets/aim_reader_init_ondetect";
import {aimReaderMultiOrgIdWithOrgId} from "../snippets/aim_reader_multi_org_id_with_org_id";
import {aimTagWithNoCommands} from "../snippets/aim_tag_with_no_commands";
import {aimTagWithPageview} from "../snippets/aim_tag_with_pageview";
import {aimTagWithPageviewOverride} from "../snippets/aim_tag_with_pageview_override";
import {aimTagWithPageviewAndSignal} from "../snippets/aim_tag_with_pageview_and_signal";
import {aimTagWithSignal} from "../snippets/aim_tag_with_signal";

export const parallelReaderPages = [
    {
        "route": routes.html[600],
        "v2Script": aimReaderInitOndetect,
        "v4Script": aimTagWithNoCommands,
    },
    {
        "dataLayerName": "aimData",
        "route": routes.html[601],
        "v2Script": aimReaderInitOndetect,
        "v4Script": aimTagWithNoCommands,
    },
    {
        "dataLayerName": "aimData",
        "route": routes.html[602],
        "v2Script": aimReaderInitOndetect,
        "v4Script": aimTagWithPageview,
    },
    {
        "route": routes.html[603],
        "v2Script": aimReaderInitOndetect,
        "v4Script": aimTagWithPageview,
    },
    {
        "route": routes.html[604],
        "v2Script": aimReaderInitOndetect,
        "v4Script": aimTagWithPageviewOverride,
    },
    {
        "route": routes.html[620],
        "v2Script": aimReaderInitOndetect,
        "v4Script": aimTagWithSignal,
    },
    {
        "route": routes.html[621],
        "v2Script": aimReaderInitOndetect,
        "v4Script": aimTagWithPageviewAndSignal,
    },
    {
        "route": routes.html[630],
        "v2Script": aimReaderMultiOrgIdWithOrgId,
        "v4Script": aimTagWithPageviewAndSignal,
    },
];
