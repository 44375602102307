export const recordConsole = (method: keyof Console = "log") => {
    const record: any[] = [];
    const originalMethod = console[method] as any;

    const finish = () => {
        (console as any)[method] = originalMethod;
        return record;
    };

    (console as any)[method] = (...args: any[]) => {
        record.push(args);
        originalMethod.apply(console, args);
    };

    return {finish};
};
