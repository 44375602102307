import * as React from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Segment} from "semantic-ui-react";

type ExternalCodeBoxProps = {
    "testId": string;
    "externalScript": string;
    "externalScriptNoModule"?: string;
    "inlineScript": string;
    "modified"?: boolean;
    "runScript"?: boolean;
    "modernBuildOnly"?: boolean;
};

export const ExternalCodeBox = ({
    testId,
    externalScript,
    externalScriptNoModule = externalScript + "&target=ie11",
    inlineScript,
    modified = false,
    runScript = true,
    modernBuildOnly = false,
}: ExternalCodeBoxProps) => {
    return (
        <Segment style={{"overflow": "auto", "whiteSpace": "nowrap"}}>
            {runScript && (
                <HelmetProvider>
                    <Helmet>
                        {!modernBuildOnly && (
                            <script
                                async
                                noModule={true}
                                src={externalScriptNoModule}
                            ></script>
                        )}
                        {!modernBuildOnly && (
                            <script
                                async
                                type="module"
                                src={externalScript}
                            ></script>
                        )}
                        {modernBuildOnly && (
                            <script async src={externalScript}></script>
                        )}
                    </Helmet>
                </HelmetProvider>
            )}
            <pre data-testid={testId} data-modified={modified}>
                {!modernBuildOnly &&
                    `<script async nomodule src="${externalScriptNoModule}"></script>`}
                {!modernBuildOnly && <br />}
                {!modernBuildOnly &&
                    `<script async type="module" src="${externalScript}"></script>>`}
                {!modernBuildOnly && <br />}
                {modernBuildOnly &&
                    `<script async src="${externalScript}"></script>`}
                <br />
                {"<script>"}
                {inlineScript}
                {"</script>"}
            </pre>
        </Segment>
    );
};
export default ExternalCodeBox;
