export const aimEmailOpenCrossTag = (
    host: string,
    state: {"dgid": string; "dgidCrossTag": string; "campaignCode"?: string}
) => {
    return `<img src="${host}/_icu.png?dgid=${state.dgid}&campaign=${
        state.campaignCode
    }" />
<img src="${host}/_icu.png?dgid=${state.dgidCrossTag}&campaign=${
    state.campaignCode
}" />
<img src="${host}/_itu.png" />
<img src="${host}/_itu.png?${Date.now()}" />
`;
};

export default aimEmailOpenCrossTag;
