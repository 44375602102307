import * as React from "react";
import {Fragment} from "react";
import {Header} from "semantic-ui-react";
import {useAimInfo} from "../hooks/use_aim_info";
import {CodeBox} from "./CodeBox";

export const InfoPane = ({apiKeys}: {"apiKeys": string[]}) => {
    const infoResults = [useAimInfo(), ...apiKeys.map(useAimInfo)];

    const formattedResults = infoResults
        .map(
            ({apiKey, output}) =>
                `AIM.info('${apiKey === undefined ? "" : apiKey}')\n${output}`
        )
        .join("\n");

    return (
        <Fragment>
            <Header as="h2">Console</Header>
            <CodeBox testId="consolePane" contents={formattedResults} />
        </Fragment>
    );
};
