type Params = {[key: string]: string | number | undefined};

export const querystring = (data: Array<Params>, separator = "?"): string => {
    return (
        separator +
        data
            .map(param => Object.entries(param))
            .reduce((acc, val) => acc.concat(val), [])
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
    );
};
