// tslint:disable:max-line-length
export const aimReaderMultiGlobalWithAttemptedDetection = (config: {
    "apiKey": string;
    "env": string;
    "host": string;
}) => `
if(typeof AIM == 'undefined'){
    (function(w,d,s,m,n,t){
        w[m]=w[m]||{init:function(){(w[m].q=w[m].q||[]).push(arguments);},ready:function(c){if('function'!=typeof c){return;}(w[m].c=w[m].c||[]).push(c);c=w[m].c;
        n.onload=n.onreadystatechange=function(){if(!n.readyState||/loaded|complete/.test(n.readyState)){n.onload=n.onreadystatechange=null;if(t.parentNode&&n.parentNode){t.parentNode.removeChild(n);}
        while(c.length){(c.shift())();}}};}},w[m].d=1*new Date();n=d.createElement(s);t=d.getElementsByTagName(s)[0];n.async=1;n.src='${
    config.host
}/javascript/beacon.js';
        ${
    config.env === "prod"
        ? ""
        : `n.setAttribute("data-sub",'${config.env}');`
}t.parentNode.insertBefore(n,t);
    })(window,document,'script','AIM');

    AIM.init('${config.apiKey}');
}

AIM.ready(function(){
    AIM.ondetect(function(json){
        console.log('AIM.ondetect: anonymous func 1 called', json);
    });
});

if(typeof AIM == 'undefined'){
    (function(w,d,s,m,n,t){
        w[m]=w[m]||{init:function(){(w[m].q=w[m].q||[]).push(arguments);},ready:function(c){if('function'!=typeof c){return;}(w[m].c=w[m].c||[]).push(c);c=w[m].c;
        n.onload=n.onreadystatechange=function(){if(!n.readyState||/loaded|complete/.test(n.readyState)){n.onload=n.onreadystatechange=null;if(t.parentNode&&n.parentNode){t.parentNode.removeChild(n);}
        while(c.length){(c.shift())();}}};}},w[m].d=1*new Date();n=d.createElement(s);t=d.getElementsByTagName(s)[0];n.async=1;n.src='${
    config.host
}/javascript/beacon.js';
        ${
    config.env === "prod"
        ? ""
        : `n.setAttribute("data-sub",'${config.env}');`
}t.parentNode.insertBefore(n,t);
    })(window,document,'script','AIM');

    AIM.init('${config.apiKey}');
}

AIM.ready(function(){
    AIM.ondetect(function(json){
        console.log('AIM.ondetect: anonymous func 2 called', json);
        throw new Error("Callback #2 Fails");
    });
});

if(typeof AIM == 'undefined'){
    (function(w,d,s,m,n,t){
        w[m]=w[m]||{init:function(){(w[m].q=w[m].q||[]).push(arguments);},ready:function(c){if('function'!=typeof c){return;}(w[m].c=w[m].c||[]).push(c);c=w[m].c;
        n.onload=n.onreadystatechange=function(){if(!n.readyState||/loaded|complete/.test(n.readyState)){n.onload=n.onreadystatechange=null;if(t.parentNode&&n.parentNode){t.parentNode.removeChild(n);}
        while(c.length){(c.shift())();}}};}},w[m].d=1*new Date();n=d.createElement(s);t=d.getElementsByTagName(s)[0];n.async=1;n.src='${
    config.host
}/javascript/beacon.js';
        ${
    config.env === "prod"
        ? ""
        : `n.setAttribute("data-sub",'${config.env}');`
}t.parentNode.insertBefore(n,t);
    })(window,document,'script','AIM');

    AIM.init('${config.apiKey}');
}

AIM.ready(function(){
    AIM.ondetect(function(json){
        console.log('AIM.ondetect: anonymous func 3 called', json);
    });
});
`;
// tslint:enable:max-line-length
export default aimReaderMultiGlobalWithAttemptedDetection;
