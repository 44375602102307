import * as React from "react";
import {Container, Dropdown, Icon, Image, Menu} from "semantic-ui-react";
import EnvironmentSwitcherSelect from "../components/html/EnvironmentSwitcherSelect";
import {version} from "../config/version";
import {legacyReaderMultiPages} from "../pages/legacy_reader_multi_pages";
import {legacyReaderSinglePages} from "../pages/legacy_reader_single_pages";
import {parallelReaderPages} from "../pages/parallel_reader_pages";
import {prebidPages} from "../pages/prebid_pages";
import {adobeAnalyticsIntegrationPages} from "../pages/adobe_analytics_integration_pages";
import {googleAnalytics4IntegrationPages} from "../pages/google_analytics4_integration_pages";
import {googleUniversalAnalyticsIntegrationPages} from "../pages/google_univeral_analytics_integration_pages";
import {gtmIntegrationPages} from "../pages/gtm_integration_pages";
import {gtmModernBuildIntegrationPages} from "../pages/gtm_modern_build_integration_pages";
import {verifyPages} from "../pages/verify_pages";
import {routes} from "../routes";

class FixedHeader extends React.Component {
    render() {
        return (
            <Container fluid={true}>
                <Menu stackable={true} fixed="top" inverted={true}>
                    <Menu.Item as="a" href={routes.root} header={true}>
                        <Image
                            size="small"
                            // tslint:disable-next-line:max-line-length
                            src="//www.dmdconnects.com/hs-fs/hubfs/images/dmd/dmd-logo-white.png?t=1536771912294&width=272&name=dmd-logo-white.png"
                            style={{"marginRight": "1.5em"}}
                            alt="AIM Web"
                        />
                        QA Suite
                        <br />
                        <br />
                        {version}
                    </Menu.Item>
                    <Dropdown item={true} simple={true} text="HTML">
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                Tag Writing
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[100].path}
                                    >
                                        {routes.html[100].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[110].path}
                                    >
                                        {routes.html[110].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[120].path}
                                    >
                                        {routes.html[120].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[130].path}
                                    >
                                        {routes.html[130].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[140].path}
                                    >
                                        {routes.html[140].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[141].path}
                                    >
                                        {routes.html[141].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[142].path}
                                    >
                                        {routes.html[142].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[180].path}
                                    >
                                        {routes.html[180].title}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item>
                                Tag Reading (Single)
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    {legacyReaderSinglePages.map(({route}) => (
                                        <Dropdown.Item
                                            as="a"
                                            href={route.path}
                                            key={route.path}
                                        >
                                            {route.title}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                Tag Reading (Multi)
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    {legacyReaderMultiPages.map(({route}) => (
                                        <Dropdown.Item
                                            as="a"
                                            href={route.path}
                                            key={route.path}
                                        >
                                            {route.title}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                Tag Reading (Slim)
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[400].path}
                                    >
                                        {routes.html[400].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[410].path}
                                    >
                                        {routes.html[410].title}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                Tag Deleting
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[500].path}
                                    >
                                        {routes.html[500].title}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item>
                                Parallel Versions
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    {parallelReaderPages.map(({route}) => (
                                        <Dropdown.Item
                                            as="a"
                                            href={route.path}
                                            key={route.path}
                                        >
                                            {route.title}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                Custom Pageviews
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[700].path}
                                    >
                                        {routes.html[700].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.html[701].path}
                                    >
                                        {routes.html[701].title}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item>
                                Integrations
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    {[
                                        ...gtmIntegrationPages,
                                        ...gtmModernBuildIntegrationPages,
                                        ...adobeAnalyticsIntegrationPages,
                                        ...googleUniversalAnalyticsIntegrationPages,
                                        ...googleAnalytics4IntegrationPages,
                                    ].map(({route}) => (
                                        <Dropdown.Item
                                            as="a"
                                            href={route.path}
                                            key={route.path}
                                        >
                                            {route.title}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item>
                                Verify
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    {verifyPages.map(({route}) => (
                                        <Dropdown.Item
                                            as="a"
                                            href={route.path}
                                            key={route.path}
                                        >
                                            {route.title}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item>
                                Prebid.js
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    {prebidPages.map(({route}) => (
                                        <Dropdown.Item
                                            as="a"
                                            href={route.path}
                                            key={route.path}
                                        >
                                            {route.title}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                Static
                                <Icon name="triangle right" />
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.static[1100].path}
                                    >
                                        {routes.static[1100].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.static[1200].path}
                                    >
                                        {routes.static[1200].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.static[1210].path}
                                    >
                                        {routes.static[1210].title}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="a"
                                        href={routes.static[1220].path}
                                    >
                                        {routes.static[1220].title}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown item={true} simple={true} text="Data">
                        <Dropdown.Menu>
                            <Dropdown.Header>AWS S3</Dropdown.Header>
                            <Dropdown.Item
                                as="a"
                                target="s3"
                                href={routes.data.s3.authenticate}
                            >
                                authenticate
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="a"
                                target="s3"
                                href={routes.data.s3.tag}
                            >
                                tag
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="a"
                                target="s3"
                                href={routes.data.s3.pageLoad}
                            >
                                page-load
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="a"
                                target="s3"
                                href={routes.data.s3.domContentLoaded}
                            >
                                dom-content-loaded
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="a"
                                target="s3"
                                href={routes.data.s3.signal}
                            >
                                signal
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="a"
                                target="s3"
                                href={routes.data.s3.fingerprint}
                            >
                                fingerprint
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="a"
                                target="s3"
                                href={routes.data.s3.errorOutput}
                            >
                                errors
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item position="right">
                        <EnvironmentSwitcherSelect />
                    </Menu.Item>
                </Menu>
            </Container>
        );
    }
}

export default FixedHeader;
