import * as React from "react";

export const AIMTagIframe = (props: {
    "aimId"?: string;
    "host": string;
    "origin": string;
}) => (
    <div>
        {props.aimId ? (
            <iframe
                title="aim-tag"
                src={`${props.host}/iframe.html?v2-qa-override=${props.aimId}&client-origin=${props.origin}`}
            />
        ) : undefined}
    </div>
);

export default AIMTagIframe;
