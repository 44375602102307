import {routes} from "../routes";
import {customDimensions} from "../snippets/google_universal_analytics/custom_dimensions";

export const googleUniversalAnalyticsIntegrationPages = [
    {
        "route": routes.html[820],
        "script": customDimensions,
    },
    {
        "route": routes.html[821],
        "script": customDimensions,
    },
];
