import React, {FormEvent, useState} from "react";
import {Container, Typography, TextField, Button} from "@mui/material";
import CodeBox from "./CodeBox";
import {routes} from "../routes";

interface Props {
    "env": string;
    "localStorageTagKey": string;
    "version": string;
    "host": string;
}

const TagWriting141 = ({
    env,
    localStorageTagKey,
    version,
}: Props): JSX.Element => {
    const [tagId, setTagId] = useState(
        localStorage.getItem(localStorageTagKey) || ""
    );

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const value = (
            e.currentTarget.elements.namedItem("input") as HTMLInputElement
        )?.value;
        localStorage.setItem(localStorageTagKey, value || "");
        setTagId(value || "");
    };

    return (
        <Container
            sx={{
                "fontFamily": "Arial",
                "fontSize": "1.2em",
                "marginBottom": "3em",
            }}
        >
            <Typography variant="h4" sx={{"marginBottom": "1em"}}>
                {version} Tag Writing: {routes.html[141].title} ({env})
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    name="input"
                    placeholder="Input Local Storage Value..."
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{"marginBottom": "1em"}}
                />
                <Button type="submit" variant="contained" color="primary">
                    Set AIM ID
                </Button>
            </form>
            <Typography variant="h5" sx={{"marginTop": "2em"}}>
                Local Storage ({localStorageTagKey})
            </Typography>
            <CodeBox testId="resultsPane" contents={tagId} />
        </Container>
    );
};

export default TagWriting141;
