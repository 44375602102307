import React from "react";
import {
    Dropdown as SemanticDropdown,
    DropdownItemProps,
    DropdownProps,
} from "semantic-ui-react";

export const Dropdown = ({
    name,
    onChange,
    options,
    selection = true,
    selectOnNavigation = true,
    ...props
}: {
    "name": string;
    "onChange": (value: string, name?: string) => void;
    "options": (DropdownItemProps | string)[];
    "selection"?: boolean;
    "selectOnNavigation"?: boolean;
} & Omit<DropdownProps, "onChange" & "options">) => {
    const normalizedOptions = options.map(option =>
        typeof option === "string" ? {"text": option, "value": option} : option
    );
    return (
        <SemanticDropdown
            {...props}
            selection={selection}
            options={normalizedOptions}
            name={name}
            selectOnNavigation={selectOnNavigation}
            onChange={(event, {value}) => onChange(String(value), name)}
        />
    );
};
