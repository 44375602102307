import React from "react";
import Box from "@mui/material/Box";
import {getConfig} from "../services/config/get_config";
import {getGlobalsFromSnippet} from "../services/get_globals_from_snippet";
import {aimReaderInitOndetect} from "../snippets/aim_reader_init_ondetect";
import {v2Integration} from "../types/custom_tests/v2_integration";
import {Environment} from "../types/environment";
import {AddScriptTag} from "./AddScriptTag";
import {AIMResultsPane} from "./AIMResultsPane";
import {CodeBox} from "./CodeBox";
import {AdditionalOptions} from "./form/additional_options";
import {TextInput} from "./form/text_input";

export const CustomV2 = ({
    env,
    "integration": {
        apiKey = getConfig(env, "aim_reader").apiKey,
        host = getConfig(env, "aim_reader").host,
    } = getConfig(env, "aim_reader"),
    onChange,
    runScript,
}: {
    "env": Environment;
    "integration"?: v2Integration;
    "onChange": (state: v2Integration) => void;
    "runScript": Boolean;
}) => {
    const state = {apiKey, host};
    const updateState = (name: keyof typeof state) => (value: string) =>
        onChange({...state, [name]: value});
    const scriptText = aimReaderInitOndetect({...state, env});
    return (
        <Box sx={{"maxWidth": "1100px", "margin": "0 auto"}}>
            <AdditionalOptions data-testid="customV2-Options">
                <TextInput
                    label="API Key"
                    defaultValue={apiKey}
                    onChange={updateState("apiKey")}
                />
                <TextInput
                    label="Host"
                    defaultValue={host}
                    onChange={updateState("host")}
                />
            </AdditionalOptions>
            {runScript && <AddScriptTag script={scriptText} />}
            <CodeBox
                testId="readerSnippet"
                contents={scriptText}
                style={{"marginBottom": "2em"}}
            />
            {runScript && (
                <AIMResultsPane
                    apiKeys={[apiKey]}
                    globals={getGlobalsFromSnippet(scriptText)}
                />
            )}
        </Box>
    );
};
