import React, {useState} from "react";
import Cookies from "universal-cookie";
import {
    Container,
    Typography,
    TextField,
    Button,
    Box,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
} from "@mui/material";
import {routes} from "../routes";

export const TagWriting140 = () => {
    const [cookieName, setCookieName] = useState("");
    const [cookieValue, setCookieValue] = useState("");
    const [message, setMessage] = useState(false);

    const dropdownOptions = [
        {
            "key": 1,
            "value": "aimtoken",
            "text": "aimtoken",
        },
        {
            "key": 2,
            "value": "dgid",
            "text": "dgid",
        },
        {
            "key": 3,
            "value": "dmd-sid",
            "text": "dmd-sid",
        },
        {
            "key": 4,
            "value": "dmd-tag",
            "text": "dmd-tag",
        },
    ];

    const handleSubmit = (e: {"preventDefault": () => void}) => {
        e.preventDefault();
        const cookie = new Cookies();
        if (cookieName && cookieValue) {
            cookie.set(cookieName, cookieValue);
            setMessage(false);
        } else {
            setMessage(true);
        }
    };

    return (
        <Container
            sx={{
                "marginBottom": "3em",
                "fontFamily": "Arial",
                "fontSize": "1.2em",
            }}
        >
            <Typography variant="h4" sx={{"marginBottom": "1em"}}>
                Tag Writing: {routes.html[140].title}
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <Select
                        value={cookieName}
                        onChange={e => setCookieName(e.target.value)}
                        displayEmpty
                        sx={{"marginBottom": "1em"}}
                    >
                        <MenuItem disabled value="">
                            Select Cookie Name
                        </MenuItem>
                        {dropdownOptions.map(option => (
                            <MenuItem key={option.key} value={option.value}>
                                {option.text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    value={cookieValue}
                    onChange={e => setCookieValue(e.target.value)}
                    placeholder="Input Cookie Value"
                    fullWidth
                    margin="normal"
                    sx={{"marginBottom": "1em"}}
                />
                <Button type="submit" variant="contained" color="primary">
                    Set Cookie
                </Button>
                {message && (
                    <Box mt={2}>
                        <FormHelperText error>
                            Please select a cookie and enter a value
                        </FormHelperText>
                    </Box>
                )}
            </form>
        </Container>
    );
};
