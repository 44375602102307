export const config = [
    {
        "text": "v2",
        "value": "aim_reader",
    },
    {
        "text": "v4",
        "value": "aim_tag",
    },
];

export default config;
