import * as qs from "qs";
import {parseQueryParams} from "./parse_query_params";

// tslint:disable-next-line:no-any
export const appendQueryParams = (
    url: string,
    params?: {[key: string]: any} | undefined
) => {
    const aUrl = new URL(url);
    const {search} = aUrl;
    const query = {
        ...parseQueryParams(search),
        ...(params || {}),
    };
    aUrl.search = qs.stringify(query);
    return aUrl.href;
};

export default appendQueryParams;
